<template>
    <b-container>
        <Banner v-if="$store.getters.selectedCampaign && $store.getters.selectedCampaign.media && $store.getters.selectedCampaign.media.length > 0"/>
        <div class="prizes">
            <b-row class="justify-content-center">
                <b-col md="6" xl="4" class="prize-container" v-for="(prize, index) in prizes" :key="index">
                    <div class="prize">
                        <div class="prize__wrapper" :style="[prize.media ? { backgroundImage: `url(${prize.media.link})` } : '']">
                            <div class="prize__title">{{prize.name}}</div>
                            <div class="prize__stock">
                                <i class="fas fa-store"></i>
                                <p class="prize__stock-value">{{prize.stock}} buc.</p>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <Back/>
        </div>
    </b-container>
</template>

<script>
import Banner from '../../components/Banner'
import Back from '../../components/BackButton'
import CampaignService from '../../api-services/Campaign'
export default {
  name: 'PizesList',
  data () {
    return {
      prizes: []
    }
  },
  components: {
    Banner,
    Back
  },
  methods: {
    getPrizes () {
      return new Promise((resolve, reject) => {
        this.prizes = []
        CampaignService.getPrizesForCampaign(this.$store.getters.selectedCampaign.id)
          .then((response) => {
            if (response.data.data.length > 0) {
              this.prizes = response.data.data
            }
            resolve(response.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  async created () {
    await this.getPrizes()
    this.$store.dispatch('hideLoading')
  }
}
</script>
