<template>
    <div class="campaign-page">
        <div class="login campaign">
            <div class="login__logo-container">
                <img src="./../../assets/images/Logo_PK.png" alt="" class="login__logo">
            </div>
            <form class="login__form campaign__form">
                <div class="form-group campaign__dropdown">
                    <label for="campaign" class="login__label">Selecteaza campanie: </label>
                    <b-form-select
                            v-model="selectedCampaign"
                            :options="campaigns"
                            value-field="id"
                            text-field="name"
                            class="custom-select login__input campaign__input"
                            id="campaign"
                    >
                        <template slot="first">
                            <option :value="null" disabled>-- Va rugam sa alegeti o campanie --</option>
                        </template>
                    </b-form-select>
                </div>
                <button type="submit" @click.prevent="selectCampaign()" class="btn login__btn campaign__btn" :disabled="!selectedCampaign">Continua</button>
            </form>
        </div>
    </div>
</template>

<script>
import CampaignService from '../../api-services/Campaign'
export default {
  name: 'SelectCampaign',
  data () {
    return {
      campaigns: [],
      selectedCampaign: null
    }
  },
  methods: {
    getCampaigns () {
      return new Promise((resolve, reject) => {
        this.campaigns = []
        CampaignService.getActiveCampaigns()
          .then((response) => {
            if (response.data.data.length > 0) {
              this.campaigns = response.data.data
            }
            resolve(response.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    selectCampaign () {
      this.$store.dispatch('changeCampaign', this.selectedCampaign).then((response) => {
        this.$router.push('/')
      }).catch((error) => {
        // console.log(error)
        this.error = error.response.data.error
      })
    }
  },
  async created () {
    await this.getCampaigns()
    this.$store.dispatch('hideLoading')
  }
}
</script>
