<template>
    <div class="back" @click="goBackTo('/')">
        <i class="fas fa-arrow-circle-left landing__icon"></i>
        Inapoi
    </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    goBackTo (page) {
      this.$router.push({ path: page })
    }
  }
}
</script>
