<template>
    <div class="login-page">
        <div class="login">
            <div class="login__logo-container">
                <img src="./../assets/images/Logo_PK.png" alt="" class="login__logo">
            </div>
            <form class="login__form">
                <p class="login-error-info text-center mb-2">{{this.error}}</p>
                <div class="form-group">
                    <label for="email" class="login__label">Utilizator: </label>
                    <div class="login__input-container">
                        <i class="fas fa-user login__icon"></i>
                        <input type="email" name="email" id="email" v-model="input.email" class="form-control login__input">
                    </div>
                </div>
                <div class="form-group">
                    <label for="password" class="login__label">Parola: </label>
                    <div class="login__input-container">
                        <i class="fas fa-lock login__icon"></i>
                        <input type="password" name="password" id="password" v-model="input.password" class="form-control login__input">
                    </div>
                </div>
                <button type="submit" @click.prevent="login()" class="btn login__btn" :disabled="!input.email || !input.password">Log in</button>
            </form>
        </div>
    </div>
</template>

<script>
import { syncForOffline, checkCookie } from '../sync'
export default {
  name: 'Login',
  data () {
    return {
      input: {
        email: '',
        password: ''
      },
      error: null
    }
  },
  methods: {
    login () {
      this.error = null
      if (this.input.password.length > 0) {
        // this.$store.dispatch('showLoading')
        let data = {
          email: this.input.email,
          password: this.input.password
        }
        this.$store.dispatch('authRequest', data).then((response) => {
          // this.$store.dispatch('hideLoading')
          // console.log(response.data.data)
          // this.$ability.update(response.data.data)
          if (!checkCookie('synced')) {
            syncForOffline()
          }
          this.$router.push('/select-campaign')
        }).catch((error) => {
          // this.$store.dispatch('hideLoading')
          console.log(error)
          if (error.response) {
            this.error = error.response.data.error
          }
        })
      } else {
        this.error = 'Va rugam sa completati emailul si parola'
      }
    }
  },
  created () {
    this.$store.dispatch('hideLoading')
    if (this.$store.getters.isAuthenticated) {
      this.$router.push('/')
    }
  }
}
</script>
